<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="switch-cover">
      <div class="container">
        <div class="row">
          <div class="col-md-5 " >
            <div class="bg-white p-5 auth-card" >

              <form method="post" action="#" v-on:submit.prevent="validateCheckoutForm">

                  <div class="row">
                    <div class="col-md-6">
                      <RouterLink class="text-gray"  to="/login"> <img src="@/client/assets/images/left.png"> Back </RouterLink>
                    </div>
                    <div class="col-md-6">
                      <div style="float: right;">
                        Step 1 of 3 <br/> <span class="text-black text-bold"  > Forgot Password </span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-5" style="clear: both;">

                  </div>

                  <div >
                    <h3>Forgot Password</h3>
                  </div>

                  <div class="mb-5" style="font-size: 12px;">
                    Enter the email of your account and we will send the email to reset your password.
                  </div>

                  <div class="form-group mb-3">
                    <label :class="{ 'text-danger': $v.user.email.$error }"><strong>Email</strong> </label> <br/>
                    <input v-model.trim="$v.user.email.$model" class="form-control" type="text" placeholder="Email" />
                    <div class="text-danger" v-if="!$v.user.email.required && $v.user.email.$dirty">Email is required</div>
                    <div class="text-danger" v-if="!$v.user.email.email && $v.user.email.$dirty">Email invalid</div>
                  </div>

                  <div class="mt-5 mb-5 ">
                    <button type="submit" style="background-color: #E42B1A !important;color:#fff;"  class=" btn-secondary btn  btn-lg  quote-btn btn-block">  <strong>Reset </strong>  </button>
                  </div>

              </form>





            </div>


          </div>
          <div class="col-md-7">
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

  import { required, email} from 'vuelidate/lib/validators'
  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
        isLoading: false,
        fullPage: true,
        user:{
          email: "",
          product:"switch"
        }
      };
    },
    mounted(){
      this.quotation = JSON.parse(localStorage.getItem("quote"));
    },

    components: { Loading },

    validations: {
      user:{
        email:{required, email}
      },
    },

    methods:{
      ...mapActions(["forgotPassword"]),

      sendPasswordResetEmail(){

        let self = this;

        this.isLoading = true;


        this.forgotPassword(this.user).then((response) => {
          self.isLoading = false;

          self.$toast.open({
            message: "We've sent you a link. Please check your email",
            type: 'success',
            duration:5000,
            position:"top-right"
          });

          self.$router.push("/login");


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        });

      },

      validateCheckoutForm(){
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.sendPasswordResetEmail()
        }
      },


    }
  }
</script>

<style>

  table td{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .personal-info{
    min-height: 80vh;
    background-color: #F2F2F2;
  }


  .sso-sign-up a{
    border:1px solid #BDBDBD;
    padding: 15px;
    margin: 5px;
    border-radius: 4px;
  }
  .sso-sign-up img{
      height: 30px;
      width: 30px;
  }
   .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #666666;
    font-size: 16px;
    font-weight: 600;
  }
  .nav-link:hover {
    border: none;
  }

  .personal-info button{
    width: 100%;
  }


</style>
